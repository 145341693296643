.psm__progress-bar {
  height: 4px !important;
  border-radius: 4px;
}

.psm__progress-bar-items {
  border-radius: 4px;
}

.psm__progress-bar-item {
  border-color: var(--background) !important;
}

.psm__progress-bar-overlay {
  height: 4px !important;
  border-radius: 4px !important;
}
