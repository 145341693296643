/*
The styles below are intended to override the default styles of Kendo UI components
*/

.k-grid {
  @apply border-0;
  background-color: transparent;
  border-color: var(--border);

  .k-grid-aria-root {
    border-width: 1px;
    @apply rounded-sm;
  }

  .k-grid-header {
    @apply border-border;

    .k-link {
      padding-inline-end: 0 !important;
    }
  }

  .k-cell-inner > .k-link > .k-sort-icon {
    @apply hidden;
  }

  .k-header {
    @apply border-0 bg-body font-medium text-foreground dark:bg-[rgba(0,0,0,95)];

    .k-grid-header-menu {
      @apply h-6 w-6 rounded-sm text-text;

      .k-svg-icon {
        @apply h-3 w-3;
      }

      &:hover {
        @apply bg-border;
      }

      &.k-active {
        @apply bg-transparent text-blue-600 text-dx-blue;
      }
    }
  }

  .k-table-td {
    @apply border-0 border-transparent text-foreground;
    font-size: 13px;
  }

  .k-table-row {
    @apply bg-white dark:bg-[rgba(0,0,0,0.2)];

    &.k-table-alt-row {
      @apply dark:bg-[rgba(0,0,0,0.65)];
      // @apply bg-secondary;
      // background-color: #f7f7f7;
    }
  }

  .k-grid-content .k-table-row {
    @apply h-10;
  }

  .k-table-tbody > .k-table-row:not(.k-detail-row):hover {
    @apply dark:bg-[rgba(0,0,0,0.9)];
  }

  .k-grid-pager {
    @apply block justify-between border-0 py-2 pl-1 pr-0;
    background-color: transparent;

    &:focus {
      @apply shadow-none;
    }
  }

  .k-grid-content {
    @apply bg-background;
  }
}

.k-popup {
  @apply mt-1 w-auto min-w-48 rounded-md border-border bg-background shadow-md dark:bg-neutral-900;

  .k-actions button {
    @apply inline-flex h-8 items-center justify-center rounded-lg border border-border bg-background px-2.5 py-2 text-xs font-medium text-text shadow-sm ring-offset-background transition-colors hover:bg-border hover:text-accent-foreground focus:bg-border focus:outline-none focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50;

    &.k-button-solid-primary {
      @apply border-blue-600 bg-blue-600 text-white hover:bg-blue-700;
    }
  }
}

.k-filter-menu-container .k-actions {
  @apply flex-row-reverse;
}

kendo-resize-sensor {
  display: none;
}

.k-loading-mask {
  .k-loading-color {
    @apply animate-pulse bg-background opacity-75;
  }

  .k-loading-image {
    &::before,
    &::after {
      border-left-color: var(--text);
      border-right-color: var(--text);
    }
  }
}
