/*
The styles below are intended to override the default styles of PrimeNG components
*/

.p-calendar,
.p-inputnumber,
.p-dropdown {
  @apply w-full;
}

.p-calendar input {
  @apply cursor-pointer;
}

.p-inputtext {
  @apply flex h-10 w-full items-center rounded-md border border-border bg-background px-3 py-2 text-sm font-normal text-text shadow-none ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium file:text-foreground placeholder:text-text-muted focus:border-dx-blue focus:ring-dx-blue disabled:pointer-events-none disabled:cursor-not-allowed disabled:opacity-60 dark:bg-body dark:bg-neutral-950;
}

.p-inputtextarea {
  height: auto;
}

.p-placeholder {
  @apply text-text-muted;
}

@import 'primeng/primeng-tooltip.scss';
@import 'primeng/primeng-datepicker.scss';
@import 'primeng/primeng-dropdown.scss';
