.p-datepicker:not(.p-datepicker-inline) {
  @apply min-w-36 rounded-md border border-solid border-border bg-background pt-1 shadow-md dark:bg-neutral-900;

  .p-yearpicker {
    @apply text-sm;
  }

  .p-datepicker-header {
    @apply border-border bg-background p-0 dark:bg-neutral-900;

    .p-datepicker-title {
      @apply flex min-w-32 items-center justify-center text-sm text-text;

      .p-datepicker-month,
      .p-datepicker-year,
      .p-datepicker-decade {
        @apply m-0 p-3 text-sm text-text;
      }
    }
  }

  .p-datepicker-calendar,
  .p-yearpicker,
  .p-monthpicker {
    @apply mb-0;

    .p-monthpicker-month,
    .p-yearpicker-year {
      @apply rounded-md p-2 text-sm text-text hover:bg-border;
    }

    thead tr th {
      @apply p-1 text-sm font-bold text-text-muted;
    }

    tbody tr td {
      @apply p-1 text-sm text-text;

      &.p-datepicker-today > span {
        @apply bg-border font-semibold text-text;
      }

      span {
        @apply h-8 w-8 rounded-md hover:bg-border;

        &.p-highlight {
          @apply bg-blue-100 font-semibold text-blue-600;
        }
      }
    }
  }

  .p-datepicker-buttonbar {
    @apply border-border p-0;
  }
}
