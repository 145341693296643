p-dropdown.p-dropdown-error .p-dropdown {
  @apply border-error text-error focus:border-error focus:ring-error focus-visible:ring-error;

  .p-placeholder {
    @apply text-error;
  }
}

.p-dropdown {
  @apply h-10 border-border bg-background dark:bg-neutral-950;

  .p-inputtext {
    @apply h-auto;
  }
}

.p-dropdown-panel {
  @apply bg-background;

  .p-dropdown-header {
    @apply border-border bg-background p-2;
  }

  .p-dropdown-items {
    @apply py-1;

    .p-dropdown-item {
      @apply bg-background p-2 hover:bg-border;
    }
  }

  .p-dropdown-items-wrapper {
    @apply rounded-b-md;
  }

  .p-dropdown-items {
    @apply px-1 py-0;

    .p-dropdown-item {
      @apply my-1 flex w-full cursor-pointer select-none items-center gap-x-2 rounded-md px-3 py-2 text-sm text-text transition duration-200 hover:bg-border focus:bg-border focus:outline-none disabled:pointer-events-none disabled:opacity-50;

      &.p-highlight {
        @apply bg-border font-medium;
      }
    }
  }
}

.p-input-icon-left > .p-inputtext {
  @apply pl-10;
}

.p-dropdown-empty-message {
  @apply cursor-default p-4 text-sm text-text-muted;
}

.no-loading .p-inputtext {
  border-radius: initial;
  background-color: initial;
  color: initial !important;
  height: initial;

  p {
    border-radius: initial;
    background-color: initial;
    // color: initial !important;
    height: initial;
  }
}
