@import '@angular/cdk/overlay-prebuilt.css';
@import '@progress/kendo-theme-default/scss/grid/_index.scss';

// @import 'tailwindcss/base' layer(tailwindcss);
// @import 'tailwindcss/components' layer(tailwindcss);
// @import 'tailwindcss/utilities' layer(tailwindcss);

// @import 'primeng/resources/themes/lara-light-blue/theme.css';
// @import 'primeng/resources/primeng.css';
// @import 'primeicons/primeicons.css';

@layer tailwind-base, primeng;

@import 'tailwindcss/base' layer(tailwind-base);
@tailwind components;
@tailwind utilities;

@import 'primeng/resources/themes/lara-light-blue/theme.css';
@import 'primeng/resources/primeng.css';
@import 'primeicons/primeicons.css';

/*
  styles to override 3rd party libraries
*/
@import 'scss/overrides/kendo.scss';
@import 'scss/overrides/primeng.scss';
@import 'scss/overrides/password-strength-meter.scss';

// base typography styles
h1 {
  @apply text-2xl font-bold text-heading;
}

h2 {
  @apply text-xl font-bold text-heading;
}

h3 {
  @apply text-lg font-bold text-heading;
}

h4 {
  @apply text-lg font-bold text-heading;
}

h5 {
  @apply text-base font-bold text-heading;
}

h6 {
  @apply text-sm font-bold text-heading;
}

p {
  @apply text-text;
}

a {
  @apply cursor-pointer text-sm font-medium text-blue-600 underline-offset-1 hover:underline focus:ring-0 dark:text-blue-400;

  &.icon-link {
    @apply hover:no-underline;

    span {
      @apply underline-offset-1 hover:underline;
    }
  }
}

// stop layout shift when scrollbar is displayed
.stable-scrollbar-gutter {
  @apply scrollbar-gutter-stable;
}

:root {
  --dx-brand: #4c2bf3;
  --dx-blue: #2277ed;
  --dx-green: #1ee994;
  --risk-med: #ffc220;
  --risk-high: #ff0000;
  --font-sans: '';
  --radius: 0.5rem;
  --sidebar-width: 260px;

  // SPARTAN UI
  --background: #f3f4f6;
  --foreground: #09090b;
  --card: #ffffff;
  --card-foreground: #09090b;
  --popover: #ffffff;
  --popover-foreground: #09090b;
  --primary: #1d5ee1;
  --primary-foreground: #f1f8ff;
  --secondary: #f4f4f5;
  --secondary-foreground: #18181b;
  --muted: #f4f4f5;
  --muted-foreground: #71717a;
  --accent: #f4f4f5;
  --accent-foreground: #18181b;
  --destructive: #ef4444;
  --destructive-foreground: #fafafa;
  --border: #e5e7eb;
  --input: #e4e4e7;
  --ring: #1d5ee1;

  // PRELINE / custom
  --body: #f9fafb;
  --background: #ffffff;
  --border: #e5e7eb;
  --border-foreground: #404040;
  --heading: #0f131a;
  --text: #1f2937;
  --text-muted: #6b7280;
  --error: #dc2626;
}

.dark {
  // SPARTAN UI
  --background: #0f172a;
  --foreground: #f2f2f2;
  --card: #1f2937;
  --card-foreground: #f2f2f2;
  --popover: #171717;
  --popover-foreground: #f2f2f2;
  --primary: #1d5ee1;
  --primary-foreground: #f1f8ff;
  --secondary: #27272a;
  --secondary-foreground: #fafafa;
  --muted: #262626;
  --muted-foreground: #a1a1aa;
  --accent: #292524;
  --accent-foreground: #fafafa;
  --destructive: #7f1d1d;
  --destructive-foreground: #fef2f2;
  --border: #374151;
  --input: #27272a;
  --ring: #1d5ee1;

  // PRELINE / custom
  --body: #171717;
  --background: #262626;
  --border: #404040;
  --border-foreground: #e5e7eb;
  --heading: #ffffff;
  --text: #e5e5e5;
  --text-muted: #929292;
  --error: #ff6363;
}

// This section styles the autofill background and text color for input, textarea, and select elements to ensure consistency with the theme.
// For light mode, it sets the autofill background to white and text color to the --text variable color.
// For dark mode, specified under the .dark class, it adjusts the autofill background and text color to match the dark theme aesthetics.
input:-webkit-autofill,
input:-webkit-autofill:enabled,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:enabled,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:enabled,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: var(--text);
  -webkit-box-shadow: 0 0 0px 1000px #fff inset !important;
  box-shadow: 0 0 0px 1000px #fff inset !important;
  background-color: #fff !important;
  @apply font-sans text-sm text-text;
}

.dark {
  input:-webkit-autofill,
  input:-webkit-autofill:enabled,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:enabled,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:enabled,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px #171717 inset !important;
    box-shadow: 0 0 0px 1000px #171717 inset !important;
    background-color: #171717 !important;
  }
}

label input {
  @apply mt-2;
}

.loading {
  @apply animate-pulse;

  // h1,
  // h2,
  // h3,
  // h4,
  // h5,
  // h6,
  p,
  span {
    @apply rounded-full bg-border;
    color: transparent !important;
  }

  // h1,
  // h2,
  // h3,
  // h4,
  // h5,
  // h6 {
  //   @apply h-7 min-w-80;
  // }

  // h3,
  // h4,
  // h5,
  // h6,
  p,
  span {
    @apply h-4;
  }

  button,
  a {
    @apply pointer-events-none;
  }

  li {
    @apply space-y-1;
  }

  .no-loading {
    border-radius: initial;
    background-color: initial;
    color: initial !important;
    height: initial;
  }
}

.loading-div,
.loading-header {
  @apply bg-border text-transparent;
}

.loading-div {
  @apply rounded-md;
}

.loading-header {
  @apply h-6 min-w-80 rounded-full;
}
